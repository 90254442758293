import React from "react"
import { AktualniKaplaniModel } from "../pages/duszpasterze-i-wspolnoty/duszpasterze"
import MainCard from "./main-card"

const DuszpasterzeComponent: React.FC<{
  aktualni_kaplani: AktualniKaplaniModel[]
  html: string
}> = props => {
  return (
    <div>
      <div className="max-w-7xl mx-auto mb-10">
        <div className="max-w-2xl mx-auto lg:max-w-none">
          <div className="space-y-12 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-6 gap-y-10">
            {props.aktualni_kaplani.map(post => {
              const parish_date_from = `W parafii od ${post.parish_date_from}`
              return (
                <MainCard
                  key={post.name}
                  thumbnail={post.picture}
                  title={post.name}
                  date={parish_date_from}
                  description={post.description}
                />
              )
            })}
          </div>
        </div>
      </div>
      <section
        dangerouslySetInnerHTML={{ __html: props.html }}
        itemProp="articleBody"
      />
    </div>
  )
}

export default DuszpasterzeComponent
