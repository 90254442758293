import * as React from "react"
import { PageProps, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { DataPropsPageGeneric } from "../../contracts/data-props-page"
import { MarkdownRemark } from "../../contracts/markdown-remark"
import DuszpasterzeComponent from "../../components/duszpasterze"

export interface AktualniKaplaniModel {
  name: string
  description: string
  picture?: string
  parish_date_from?: string
}

interface DuszpasterzeModel {
  title: string
  description?: string
  date?: string
  thumbnail?: string
  layout: string
  aktualni_kaplani: AktualniKaplaniModel[]
}

interface DuszpasterzeMarkdownRemark extends MarkdownRemark {
  frontmatter: DuszpasterzeModel
}

const Duszpasterze: React.FC<
  PageProps<DataPropsPageGeneric<DuszpasterzeMarkdownRemark>>
> = ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout title={post.frontmatter.title} location={location}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
        </header>
        <DuszpasterzeComponent
          aktualni_kaplani={post.frontmatter.aktualni_kaplani}
          html={post.html}
        />
      </article>
    </Layout>
  )
}

export default Duszpasterze

export const query = graphql`
  {
    markdownRemark(frontmatter: { layout: { eq: "duszpasterze" } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY", locale: "pl")
        aktualni_kaplani {
          description
          name
          picture
          parish_date_from(formatString: "YYYY", locale: "pl")
        }
      }
    }
  }
`
